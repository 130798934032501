<template>
    <div>認証中です、しばらくお待ちください</div>
</template>

<script>
export default {
    name: 'Authorization-artist',
    components: {},
    data: function () {
    return {
    }
    },
    async created() {
    const artist_id = this.$route.params.artist_id;
    const remember_token = this.$route.params.remember_token;
    this.authorization(artist_id, remember_token);

    },
    methods: {
    async authorization(artist_id, remember_token) {
        const response = await this.$http.put('artist-authorize', {
        artist_id, remember_token
        });
        await this.$store.dispatch('signInArtist ', response.data);
        console.log(response.data)
        this.$router.push({name: 'login', params: { flashMessage: 'メール認証が完了しました。' } });
    }
    }
}
</script>